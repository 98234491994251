export const breakpoints = {
  base: '0',
  sm: '640px',
  md: '960px',
  lg: '1120px',
  xl: '1440px',
} as const;

export const orderedBreakpointKeys = ['base', 'sm', 'md', 'lg', 'xl'] as const;

export type BreakpointKeys = (typeof orderedBreakpointKeys)[number];

type BreakPointRecord = Record<
  BreakpointKeys | `${BreakpointKeys}Query`,
  string
>;

export const screen = Object.entries(breakpoints).reduce(
  (acc, [breakpoint, value]) => {
    const str = `(min-width: ${value})`;

    return {
      ...acc,
      [`${breakpoint}Query`]: str,
      [`${breakpoint}`]: `@media${str}`,
    };
  },
  {} as BreakPointRecord,
);

// Utils

/**
 * Creates a map with keys sorted by the breakpoint order - base -> xl
 * @param bp A breakpoint object
 */
export const mapFromBreakpointObject = <T,>(bp: {
  [K in BreakpointKeys]?: T;
}) => {
  const entries = Object.entries(bp) as [BreakpointKeys, T][];

  const sorted = entries.sort(
    (a, b) =>
      orderedBreakpointKeys.indexOf(a[0]) - orderedBreakpointKeys.indexOf(b[0]),
  );

  return new Map(sorted);
};

/**
 * Returns a breakpoint from a map, or will look for the next lowest breakpoint that has data
 * @param map A Map created by `mapFromBreakpointObject`
 * @param target The breakpoint you want data for
 */
export const findClosestBreakpoint = <T extends Map<BreakpointKeys, any>>(
  map: T,
  target: BreakpointKeys,
) => {
  if (map.has(target)) return map.get(target);

  const startIndex = orderedBreakpointKeys.indexOf(target);

  // First, look down
  for (let i = startIndex; i >= 0; i--) {
    const key = orderedBreakpointKeys[i];

    if (map.has(key)) {
      return map.get(key);
    }
  }

  // In the event nothing exists, look up
  for (let i = 0; i < orderedBreakpointKeys.length; i++) {
    const key = orderedBreakpointKeys[i];

    if (map.has(key)) {
      return map.get(key);
    }
  }
};
