import { useEffect } from 'react';
import { useLocalStorageValue } from '@react-hookz/web';

import { getCookie } from '@/lib/utils';

const isDateOneHourOrMoreInThePast = (date) => {
  const oneHour = 1000 * 60 * 60;
  const oneHourAgo = Date.now() - oneHour;
  return date < oneHourAgo;
};

export const useSixSense = () => {
  const defaultValue = {
    '6si_company_match': null,
    segment_311288_6si: null,
    segment_275342_6si: null,
  };
  const previousIdentityRequestCompletedTime = useLocalStorageValue(
    'previousIdentityRequestCompletedTime',
    {
      defaultValue,
    },
  );

  useEffect(() => {
    let sixSenseData = null;

    const shouldAllowAPIReq = (keyProperty) =>
      !previousIdentityRequestCompletedTime?.value[keyProperty] ||
      isDateOneHourOrMoreInThePast(
        previousIdentityRequestCompletedTime?.value[keyProperty],
      );

    const postIdentifyRequest = async (keyProperty) => {
      window.DY.API('callback', async () => {
        const dyid = getCookie('_dyid');
        if (!dyid?.length) return;
        await fetch('/api/identify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sixSenseData, dyid }),
        });
        previousIdentityRequestCompletedTime.set({
          ...previousIdentityRequestCompletedTime.value,
          [keyProperty]: Date.now(),
        });
      });
    };

    const handleDataLayerPush = (e) => {
      if (!sixSenseData) {
        if (
          Object.prototype.hasOwnProperty.call(e.detail, '6si_company_match') &&
          shouldAllowAPIReq('6si_company_match')
        ) {
          sixSenseData = { ...e.detail };
          postIdentifyRequest('6si_company_match');
        }
        if (
          Object.prototype.hasOwnProperty.call(
            e.detail,
            'segment_311288_6si',
          ) &&
          shouldAllowAPIReq('segment_311288_6si')
        ) {
          sixSenseData = {
            segment_311288_6si: e.detail['segment_311288_6si'],
          };
          postIdentifyRequest('segment_311288_6si');
        }
        if (
          Object.prototype.hasOwnProperty.call(
            e.detail,
            'segment_275342_6si',
          ) &&
          shouldAllowAPIReq('segment_275342_6si')
        ) {
          sixSenseData = {
            segment_275342_6si: e.detail['segment_275342_6si'],
          };
          postIdentifyRequest('segment_275342_6si');
        }
      }

      // Send 6Sense data to Heap
      if (
        Object.prototype.hasOwnProperty.call(e.detail, '6si_company_match') &&
        e.detail['6si_company_match'] === 'Match'
      ) {
        window.heap?.addUserProperties(e.detail);
      }
    };

    window.addEventListener('dataLayerPush', handleDataLayerPush);

    return () => {
      window.removeEventListener('dataLayerPush', handleDataLayerPush);
    };
  }, [previousIdentityRequestCompletedTime]);
};
