import { useMemo } from 'react';
import { LiveQueryProvider } from 'next-sanity/preview';

import { getClient } from '@/lib/sanityUtils';

import type { ReactNode } from 'react';

export const PreviewProvider = ({
  children,
  token,
}: {
  children: ReactNode;
  token: string;
}) => {
  if (!token) throw new TypeError('Missing token');
  const client = useMemo(() => getClient({ token }), [token]);
  return <LiveQueryProvider client={client}>{children}</LiveQueryProvider>;
};
