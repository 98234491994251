import { useEffect } from 'react';
import { GoogleTagManager } from '@next/third-parties/google';
import clsx from 'clsx';
import { LazyMotion } from 'framer-motion';
import { Router, useRouter } from 'next/router';

import {
  Intercom,
  ProductionScripts,
  SixSenseDataProxy,
} from '@/components/common/inline-scripts';
import { GlobalStyles } from '@/components/layout/GlobalStyles';
import { PreviewProvider } from '@/components/layout/PreviewProvider';
import { useSixSense } from '@/lib/useSixSense';
import {
  addCookieForUTM,
  addUTMToPlatformLinks,
  GTMPageView,
} from '@/lib/utils';
import { saans } from '@/styles/fonts';

import type { AppProps } from 'next/app';

const GTM_ID = process.env.NODE_ENV === 'production' ? 'GTM-KWQD2SP' : '';

const loadMotionFeatures = () =>
  import('@/lib/motionFeatures').then((res) => res.default);

const App = ({ Component, pageProps }: AppProps) => {
  const { draftMode, token } = pageProps;
  const router = useRouter();
  useSixSense();

  useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);

    Router.events.on('routeChangeComplete', handleRouteChange);
    Router.events.on('routeChangeComplete', addUTMToPlatformLinks);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
      Router.events.off('routeChangeComplete', addUTMToPlatformLinks);
    };
  }, []);

  useEffect(() => {
    if (
      router.isReady &&
      Object.keys(router.query).filter((key) => /utm/.test(key)).length > 0
    ) {
      addCookieForUTM(router.query);
      addUTMToPlatformLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const appContents = (
    <LazyMotion features={loadMotionFeatures} strict>
      <GlobalStyles />
      <div className={clsx(saans.className)}>
        <Component {...pageProps} key={router.asPath.split('?')[0]} />
      </div>
      <GoogleTagManager gtmId={GTM_ID} />
      <ProductionScripts />
      <Intercom />
      <SixSenseDataProxy />
    </LazyMotion>
  );

  if (draftMode) {
    return <PreviewProvider token={token}>{appContents}</PreviewProvider>;
  }

  return appContents;
};

export default App;
