import { css } from '@emotion/react';

// Please remove me as soon as possible!
export const legacy = css`
  :root {
    --colour-error-rgb: 224, 34, 0;
    --colour-text-inverse-error-rgb: 255, 159, 141;

    --colour-black-rgb: 0, 0, 0;
    --colour-grey: #8c8c8c;
    --colour-red: #bf2600;
    --colour-inverse-text: #f5f5f5;
    --colour-inverse-subtle-text: #f5f5f5b8;
    --colour-border-base: #2424562e;
    --colour-border-subtle: #2424561f;
    --colour-button-action: #4d61f4;
    --colour-surface-base: #f5f5f5;
    --colour-border-strong: #d8d8e1;
    --colour-surface-inverse-strong: #1b1b41;

    --colour-inverse-base: #f5f5f5;
    --colour-inverse-action: #d2d7fc;

    --colour-inverse-subtle-text: #f5f5f5b8;

    --colour-inverse-subtle-dark-text: #242456b8;
    --colour-inverse-subtle: #242456e0;

    --colour-border-inverse-strong: #f5f5f53d;

    // Still used in a few older components
    --layout-gutter: 20px;

    @media (min-width: 976px) {
      --layout-gutter: 30px;
    }
    @media (min-width: 1140px) {
      --layout-gutter: 40px;
    }
    @media (min-width: 1440px) {
      --layout-gutter: 80px;
    }
  }
`;
