import { css } from '@emotion/react';

export const typography = css`
  :root {
    --font-family-saans: 'saans';
    --font-feature-settings: 'ss04', 'ss03';

    --font-weight-regular: 400;
    --font-weight-medium: 570;
    --font-weight-semibold: 670;

    --line-height-compact: 1.2;
    --line-height-loose: 1.5;

    --letter-spacing-x-compact: -0.02em;
    --letter-spacing-compact: -0.01em;
    --letter-spacing-loose: 0.01em;
    --letter-spacing-x-loose: 0.02em;

    // Mobile
    --font-size-1: 0.875rem; // 14px
    --font-size-2: 1rem; //16px
    --font-size-3: 1.125rem; // 18px
    --font-size-4: 1.125rem; // 18px
    --font-size-5: 1.25rem; // 20px
    --font-size-6: 1.5rem; // 24px
    --font-size-7: 1.75rem; //28px
    --font-size-8: 2rem; // 32px
    --font-size-9: 2.25rem; // 36px
    --font-size-10: 2.625rem; // 42px
    --font-size-11: 3rem; // 48px

    // Tablet
    @media (min-width: 640px) and (max-width: 1120px) {
      --font-size-1: 0.875rem; // 14px
      --font-size-2: 1rem; //16px
      --font-size-3: 1.125rem; // 18px
      --font-size-4: 1.25rem; // 20px
      --font-size-5: 1.375rem; // 22px
      --font-size-6: 1.625rem; // 26px
      --font-size-7: 2rem; // 32px
      --font-size-8: 2.375rem; // 38px
      --font-size-9: 3rem; // 48px
      --font-size-10: 3.75rem; // 60px
      --font-size-11: 4.5rem; // 72px
    }

    // Desktop
    @media (min-width: 1121px) {
      --font-size-1: 0.875rem; // 14px
      --font-size-2: 1rem; //16px
      --font-size-3: 1.125rem; // 18px
      --font-size-4: 1.25rem; // 20px
      --font-size-5: 1.5rem; // 24px
      --font-size-6: 1.75rem; // 28px
      --font-size-7: 2.25rem; // 36px
      --font-size-8: 3rem; // 48px
      --font-size-9: 4rem; // 64px
      --font-size-10: 5rem; // 80px
      --font-size-11: 5.75rem; // 92px
    }
  }
`;

// Elements

export const displayXLarge = css`
  font-size: var(--font-size-11);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-x-compact);
`;

export const displayLarge = css`
  font-size: var(--font-size-10);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-x-compact);
`;

export const displayMedium = css`
  font-size: var(--font-size-9);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-compact);

  @media (min-width: 640px) {
    letter-spacing: var(--letter-spacing-x-compact);
  }
`;

export const displaySmall = css`
  font-size: var(--font-size-8);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-compact);

  @media (min-width: 1121px) {
    letter-spacing: var(--letter-spacing-x-compact);
  }
`;

export const headingLarge = css`
  font-size: var(--font-size-7);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-compact);
`;

export const headingMedium = css`
  font-size: var(--font-size-6);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-compact);
`;

export const headingSmall = css`
  font-size: var(--font-size-4);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-loose);
`;

export const subheadingLarge = css`
  font-size: var(--font-size-6);
  line-height: var(--line-height-loose);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const subheadingMedium = css`
  font-size: var(--font-size-5);
  line-height: var(--line-height-loose);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const subheadingSmall = css`
  font-size: var(--font-size-4);
  line-height: var(--line-height-loose);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const bodyLarge = css`
  font-size: var(--font-size-3);
  line-height: var(--line-height-loose);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const bodyMedium = css`
  font-size: var(--font-size-2);
  line-height: var(--line-height-loose);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const bodySmall = css`
  font-size: var(--font-size-1);
  line-height: var(--line-height-loose);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const labelLarge = css`
  font-size: var(--font-size-3);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const labelMedium = css`
  font-size: var(--font-size-2);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const labelSmall = css`
  font-size: var(--font-size-1);
  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-x-loose);
`;

export const typographyStyles = {
  'display-xl': displayXLarge,
  'display-lg': displayLarge,
  'display-md': displayMedium,
  'display-sm': displaySmall,
  'heading-lg': headingLarge,
  'heading-md': headingMedium,
  'heading-sm': headingSmall,
  'subheading-lg': subheadingLarge,
  'subheading-md': subheadingMedium,
  'subheading-sm': subheadingSmall,
  'body-lg': bodyLarge,
  'body-md': bodyMedium,
  'body-sm': bodySmall,
  'label-lg': labelLarge,
  'label-md': labelMedium,
  'label-sm': labelSmall,
};

export type TypographySizeTypes = keyof typeof typographyStyles;
