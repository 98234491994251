import { css } from '@emotion/react';

export const colours = {
  lunar: {
    1000: { value: 'rgb(0, 0, 0)', token: '--lunar-1000' }, // Brand - Blackhole
    900: { value: 'rgb(35, 35, 34)', token: '--lunar-900' },
    800: { value: 'rgb(59, 59, 58)', token: '--lunar-800' },
    700: { value: 'rgb(85, 85, 83)', token: '--lunar-700' }, // Charcoal
    600: { value: 'rgb(113, 113, 111)', token: '--lunar-600' }, // Light Charcoal
    500: { value: 'rgb(162, 161, 159)', token: '--lunar-500' },
    400: { value: 'rgb(162, 161, 159)', token: '--lunar-400' },
    300: { value: 'rgb(188, 187, 184)', token: '--lunar-300' },
    200: { value: 'rgb(218, 217, 214)', token: '--lunar-200' },
    100: { value: 'rgb(237, 236, 232)', token: '--lunar-100' },
    50: { value: 'rgb(248, 247, 243)', token: '--lunar-50' }, // Grey
    0: { value: 'rgb(255, 255, 255)', token: '--lunar-0' },
  },
  meteor: {
    900: { value: 'rgb(33, 34, 35)', token: '--meteor-900' },
    800: { value: 'rgb(57, 58, 59)', token: '--meteor-800' },
    700: { value: 'rgb(84, 85, 85)', token: '--meteor-700' },
    600: { value: 'rgb(111, 112, 113)', token: '--meteor-600' },
    500: { value: 'rgb(144, 146, 147)', token: '--meteor-500' },
    400: { value: 'rgb(160, 161, 162)', token: '--meteor-400' },
    300: { value: 'rgb(185, 187, 188)', token: '--meteor-300' },
    200: { value: 'rgb(216, 217, 218)', token: '--meteor-200' },
    100: { value: 'rgb(235, 236, 237)', token: '--meteor-100' },
    50: { value: 'rgb(247, 248, 249)', token: '--meteor-50' },
  },
  ultraviolet: {
    900: { value: 'rgb(43, 50, 109)', token: '--ultraviolet-900' },
    700: { value: 'rgb(129, 137, 191)', token: '--ultraviolet-700' },
    500: { value: 'rgb(74, 95, 247)', token: '--ultraviolet-500' },
    100: { value: 'rgb(230, 232, 254)', token: '--ultraviolet-100' },
  },
  eclipse: {
    500: { value: 'rgb(214, 238, 64)', token: '--eclipse-500' }, // Brand - Dark Eclipse / Dark Lime Green
    200: { value: 'rgb(221, 252, 157)', token: '--eclipse-200' }, // Brand -  Eclipse / Lime
  },
  sunbeam: {
    500: { value: 'rgb(251, 179, 72)', token: '--sunbeam-500' }, // Brand - Dark Sunbeam / Dark Orange
    200: { value: 'rgb(252, 214, 157)', token: '--sunbeam-200' }, // Brand - Sunbeam / Orange
  },
  galaxy: {
    500: { value: 'rgb(132, 35, 255)', token: '--galaxy-500' }, // Brand - Galaxy / Purple
    200: { value: 'rgb(238, 224, 255)', token: '--galaxy-200' }, // Brand - Light Galaxy / Purple
  },
  red: {
    800: { value: 'rgb(62, 20, 17)', token: '--red-800' },
    600: { value: 'rgb(199, 66, 54)', token: '--red-600' },
    500: { value: 'rgb(241, 79, 65)', token: '--red-500' }, // Brand - Red
    300: { value: 'rgb(255, 162, 153)', token: '--red-300' },
    100: { value: 'rgb(255, 231, 229)', token: '--red-100' }, // Brand - Red Tint
  },
  green: {
    400: { value: 'rgb(73, 226, 132)', token: '--green-400' }, // Brand - Green
    100: { value: 'rgb(221, 251, 220)', token: '--green-100' }, // Brand - Green Tint
  },
  yellow: {
    400: { value: 'rgb(255, 214, 85)', token: '--yellow-400' }, // Brand - Yellow
    100: { value: 'rgb(255, 247, 176)', token: '--yellow-100' }, // Brand - Yellow Tint
  },
  blue: {
    400: { value: 'rgb(81, 166, 244)', token: '--blue-400' }, // Brand - Blue
    100: { value: 'rgb(210, 233, 255)', token: '--blue-100' }, // Brand - Blue Tint
  },
} as const;

export const colourTokens = {
  background: {
    subtle: { colour: colours.lunar[0], token: '--background-subtle' },
    warmBase: { colour: colours.lunar[50], token: '--background-warm-base' },
    warmStrong: {
      colour: colours.lunar[100],
      token: '--background-warm-strong',
    },
    coolBase: { colour: colours.meteor[50], token: '--background-cool-base' },
    coolStrong: {
      colour: colours.meteor[100],
      token: '--background-cool-strong',
    },
    primary: {
      colour: colours.ultraviolet[500],
      token: '--background-primary',
    },
    galaxy: { colour: colours.galaxy[500], token: '--background-galaxy' },
  },

  surface: {
    subtle: { colour: colours.lunar[0], token: '--surface-subtle' },
    warmBase: { colour: colours.lunar[50], token: '--surface-warm-base' },
    warmStrong: { colour: colours.lunar[100], token: '--surface-warm-strong' },
    coolBase: { colour: colours.meteor[50], token: '--surface-cool-base' },
    coolStrong: { colour: colours.meteor[100], token: '--surface-cool-strong' },
    inverseSubtle: {
      colour: colours.lunar[700],
      token: '--surface-inverse-subtle',
    },
    inverseBase: {
      colour: colours.lunar[800],
      token: '--surface-inverse-base',
    },
    inverseStrong: {
      colour: colours.lunar[900],
      token: '--surface-inverse-strong',
    },
    primarySubtle: {
      colour: colours.ultraviolet[100],
      token: '--surface-primary-subtle',
    },
    primary: { colour: colours.ultraviolet[500], token: '--surface-primary' },
    inversePrimary: {
      colour: colours.ultraviolet[900],
      token: '--surface-inverse-primary',
    },
    dangerSubtle: {
      colour: colours.red[100],
      token: '--surface-danger-subtle',
    },
    dangerStrong: {
      colour: colours.red[500],
      token: '--surface-danger-strong',
    },
    inverseDanger: {
      colour: colours.red[800],
      token: '--surface-inverse-danger',
    },
    eclipse: {
      colour: colours.eclipse[200],
      token: '--surface-eclipse',
    },
    darkEclipse: {
      colour: colours.eclipse[500],
      token: '--surface-dark-eclipse',
    },
    lightGalaxy: {
      colour: colours.galaxy[200],
      token: '--surface-light-galaxy',
    },
    galaxy: {
      colour: colours.galaxy[500],
      token: '--surface-galaxy',
    },
    sunbeam: {
      colour: colours.sunbeam[200],
      token: '--surface-sunbeam',
    },
    darkSunbeam: {
      colour: colours.sunbeam[500],
      token: '--surface-dark-sunbeam',
    },
    lightUltraviolet: {
      colour: colours.ultraviolet[100],
      token: '--surface-light-ultraviolet',
    },
    ultraviolet: {
      colour: colours.ultraviolet[500],
      token: '--surface-ultraviolet',
    },
    darkUltraviolet: {
      colour: colours.ultraviolet[900],
      token: '--surface-dark-ultraviolet',
    },
  },

  text: {
    strong: { colour: colours.lunar[900], token: '--text-strong' },
    warmBase: { colour: colours.lunar[700], token: '--text-warm-base' },
    warmSubtle: { colour: colours.lunar[600], token: '--text-warm-subtle' },
    coolBase: { colour: colours.meteor[700], token: '--text-cool-base' },
    inverseCoolBase: {
      colour: colours.meteor[100],
      token: '--text-inverse-cool-base',
    },
    coolSubtle: { colour: colours.meteor[600], token: '--text-cool-subtle' },
    inverseStrong: { colour: colours.lunar[0], token: '--text-inverse-strong' },
    inverseWarmBase: {
      colour: colours.lunar[100],
      token: '--text-inverse-warm-base',
    },
    inverseWarmSubtle: {
      colour: colours.lunar[200],
      token: '--text-inverse-warm-subtle',
    },
    inverseCoolSubtle: {
      colour: colours.meteor[200],
      token: '--text-inverse-cool-subtle',
    },
    action: { colour: colours.ultraviolet[500], token: '--text-action' },
    actionDark: {
      colour: colours.ultraviolet[900],
      token: '--text-action-dark',
    },
    inverseAction: {
      colour: colours.blue[400],
      token: '--text-inverse-action',
    },
    dangerBase: { colour: colours.red[600], token: '--text-danger-base' },
    inverseDangerBase: {
      colour: colours.red[300],
      token: '--text-inverse-danger-base',
    },
    inverseDanger: { colour: colours.red[100], token: '--text-inverse-danger' },
    eclipse: { colour: colours.eclipse[200], token: '--text-eclipse' },
    sunbeam: { colour: colours.sunbeam[200], token: '--text-sunbeam' },
    galaxy: { colour: colours.galaxy[500], token: '--text-galaxy' },
  },

  icon: {
    strong: { colour: colours.lunar[900], token: '--icon-strong' },
    coolBase: { colour: colours.meteor[700], token: '--icon-cool-base' },
    coolSubtle: { colour: colours.meteor[600], token: '--icon-cool-subtle' },
    warmBase: { colour: colours.lunar[700], token: '--icon-warm-base' },
    warmSubtle: { colour: colours.lunar[600], token: '--icon-warm-subtle' },
    inverseStrong: { colour: colours.lunar[0], token: '--icon-inverse-strong' },
    inverseCoolBase: {
      colour: colours.meteor[100],
      token: '--icon-inverse-cool-base',
    },
    inverseCoolSubtle: {
      colour: colours.meteor[200],
      token: '--icon-inverse-cool-subtle',
    },
    inverseWarmBase: {
      colour: colours.lunar[100],
      token: '--icon-inverse-warm-base',
    },
    inverseWarmSubtle: {
      colour: colours.lunar[200],
      token: '--icon-inverse-warm-subtle',
    },
    action: { colour: colours.ultraviolet[500], token: '--icon-action' },
    actionDark: {
      colour: colours.ultraviolet[900],
      token: '--icon-action-dark',
    },
    inverseAction: {
      colour: colours.blue[400],
      token: '--icon-inverse-action',
    },
    dangerBase: { colour: colours.red[600], token: '--icon-danger-base' },
    inverseDanger: { colour: colours.red[300], token: '--icon-inverse-danger' },
    eclipse: { colour: colours.eclipse[200], token: '--icon-eclipse' },
    sunbeam: { colour: colours.sunbeam[200], token: '--icon-sunbeam' },
    galaxy: { colour: colours.galaxy[500], token: '--icon-galaxy' },
  },

  border: {
    inverseStrong: {
      colour: colours.lunar[500],
      token: '--border-inverse-strong',
    },
    inverseBase: { colour: colours.lunar[600], token: '--border-inverse-base' },
    inverseSubtle: {
      colour: colours.lunar[700],
      token: '--border-inverse-subtle',
    },
    strong: { colour: colours.lunar[300], token: '--border-strong' },
    warmSubtle: { colour: colours.lunar[100], token: '--border-warm-subtle' },
    warmBase: { colour: colours.lunar[200], token: '--border-warm-base' },
    coolSubtle: { colour: colours.meteor[200], token: '--border-cool-subtle' },
    coolBase: { colour: colours.meteor[300], token: '--border-cool-base' },
    action: { colour: colours.ultraviolet[500], token: '--border-action' },
    actionDark: {
      colour: colours.ultraviolet[900],
      token: '--border-action-dark',
    },
    inverseAction: {
      colour: colours.blue[400],
      token: '--border-inverse-action',
    },
    danger: { colour: colours.red[500], token: '--border-danger' },
    inverseDangerBase: {
      colour: colours.red[300],
      token: '--border-inverse-danger-base',
    },
    inverseDanger: {
      colour: colours.red[100],
      token: '--border-inverse-danger',
    },
  },

  decorative: {
    lightUltraviolet: {
      colour: colours.ultraviolet[100],
      token: '--decorative-light-ultraviolet',
    },
    ultraviolet: {
      colour: colours.ultraviolet[500],
      token: '--decorative-ultraviolet',
    },
    darkUltraviolet: {
      colour: colours.ultraviolet[900],
      token: '--decorative-dark-ultraviolet',
    },
    starlight: {
      colour: colours.ultraviolet[100],
      token: '--decorative-starlight',
    },
    eclipse: { colour: colours.eclipse[200], token: '--decorative-eclipse' },
    darkEclipse: {
      colour: colours.eclipse[500],
      token: '--decorative-dark-eclipse',
    },
    darkSunbeam: {
      colour: colours.sunbeam[200],
      token: '--decorative-dark-sunbeam',
    },
    sunbeam: { colour: colours.sunbeam[500], token: '--decorative-sunbeam' },
    galaxy: { colour: colours.galaxy[500], token: '--decorative-galaxy' },
    lightGalaxy: {
      colour: colours.galaxy[200],
      token: '--decorative-light-galaxy',
    },
    softGalaxy: {
      colour: colours.ultraviolet[700],
      token: '--decorative-soft-galaxy',
    },
    green: { colour: colours.green[400], token: '--decorative-green' },
    greenTint: { colour: colours.green[100], token: '--decorative-green-tint' },
    red: { colour: colours.red[500], token: '--decorative-red' },
    redTint: { colour: colours.red[100], token: '--decorative-red-tint' },
    blue: { colour: colours.blue[400], token: '--decorative-blue' },
    blueTint: { colour: colours.blue[100], token: '--decorative-blue-tint' },
    yellow: { colour: colours.yellow[400], token: '--decorative-yellow' },
    yellowTint: {
      colour: colours.yellow[100],
      token: '--decorative-yellow-tint',
    },
    warmStrong: {
      colour: colours.lunar[100],
      token: '--decorative-warm-strong',
    },
    white: {
      colour: colours.lunar[0],
      token: '--decorative-white',
    },
    lightLunar: {
      colour: colours.lunar[100],
      token: '--decorative-light-lunar',
    },
  },
} as const;

/**
 * Naively flatten the colours object
 *
 * @param {object} obj An object describing colours
 * @returns {string}
 */
export const flattenObjectToArray = (obj) => {
  return Object.values(obj)
    .map((sub) => Object.values(sub))
    .flat();
};

/**
 * Converts a colour scheme object to a CSS string
 */
const createColourVariables = (obj) => {
  const flattened = flattenObjectToArray(obj);

  return Object.values(flattened).reduce<string>((acc, current) => {
    return `${acc} ${current.token}: ${current.value};`;
  }, '');
};

const createTokenVariables = (obj) => {
  const flattened = flattenObjectToArray(obj);

  return flattened.reduce<string>((acc, current) => {
    return `${acc} ${current.token}: var(${current.colour.token});`;
  }, '');
};

// Colour variables and tokens
export const colourCss = css`
  :root {
    // Primitives

    ${createColourVariables(colours)}
    ${createTokenVariables(colourTokens)}

    --transparent: transparent;
  }
`;
