import { css } from '@emotion/react';

import { screen } from '@/components/common/breakpoints';

// Spacing and other values that denote distance, such as border radius
export const measures = css`
  :root {
    /* ---- Spacing Scale  ---- */

    --spacing-0: 0rem; // 0px
    --spacing-25: 0.125rem; // 2px
    --spacing-50: 0.25rem; // 4px
    --spacing-100: 0.5rem; // 8px
    --spacing-125: 0.625rem; // 10px
    --spacing-150: 0.75rem; // 12px
    --spacing-200: 1rem; // 16px
    --spacing-250: 1.25rem; // 20px
    --spacing-300: 1.5rem; // 24px
    --spacing-350: 1.75rem; // 28px
    --spacing-400: 2rem; // 32px
    --spacing-450: 2.25rem; // 36px
    --spacing-500: 2.5rem; // 40px
    --spacing-550: 2.75rem; // 44px ❌ - deprecated, to be removed
    --spacing-600: 3rem; // 48px
    --spacing-650: 3.25rem; // 52px
    --spacing-700: 3.5rem; // 56px
    --spacing-800: 4rem; // 64px
    --spacing-900: 4.5rem; // 72px
    --spacing-1000: 5rem; // 80px
    --spacing-1200: 6rem; // 96px
    --spacing-1400: 7rem; // 112px
    --spacing-1500: 7.5rem; // 120px
    --spacing-1600: 8rem; // 128px
    --spacing-1800: 9rem; // 144px
    --spacing-2000: 10rem; // 160px

    /* ---- Spacing Variables (❌ DEPRECATED as of March 2024 - See below) ---- */

    /* Mobile+ */
    --spacing-xxx-small: var(--spacing-50); // 4px
    --spacing-xx-small: var(--spacing-100); // 8px
    --spacing-x-small: var(--spacing-150); // 12px
    --spacing-small: var(--spacing-200); // 16px
    --spacing-medium: var(--spacing-250); // 20px
    --spacing-large: var(--spacing-300); // 24px
    --spacing-x-large: var(--spacing-400); // 32px
    --spacing-xx-large: var(--spacing-500); // 40px
    --spacing-xxx-large: var(--spacing-600); // 48px
    --spacing-xxxx-large: var(--spacing-800); // 64px

    --section-gutter: var(--spacing-300);

    /* Tablet+ */
    ${screen.sm} {
      --spacing-medium: var(--spacing-300); // 24px
      --spacing-large: var(--spacing-350); // 28px
      --spacing-x-large: var(--spacing-400); // 32px
      --spacing-xx-large: var(--spacing-500); // 40px
      --spacing-xxx-large: var(--spacing-700); // 56px
      --spacing-xxxx-large: var(--spacing-1000); // 80px

      --section-gutter: var(--spacing-600);
    }

    /* Desktop+ */
    ${screen.md} {
      --spacing-large: var(--spacing-400); // 32px
      --spacing-x-large: var(--spacing-500); // 40px
      --spacing-xx-large: var(--spacing-600); // 48px
      --spacing-xxx-large: var(--spacing-800); // 64px
      --spacing-xxxx-large: var(--spacing-1500); // 120px

      --section-gutter: var(--spacing-800);
    }

    ${screen.lg} {
      --section-gutter: var(--spacing-1000);
    }

    /* ---- New Spacing Variables as of July 2024 ---- */

    /* ---- Component Spacing ---- */

    /* Mobile+ */
    --space-component-xxs: var(--spacing-25); // 2px
    --space-component-xs: var(--spacing-50); // 4px
    --space-component-sm: var(--spacing-100); // 8px
    --space-component-md: var(--spacing-150); // 12px
    --space-component-lg: var(--spacing-200); // 16px
    --space-component-xl: var(--spacing-250); // 20px
    --space-component-xxl: var(--spacing-350); // 28px

    /* Tablet & Small Desktop+ */
    ${screen.sm} {
      --space-component-xxs: var(--spacing-25); // 2px
      --space-component-xs: var(--spacing-50); // 4px
      --space-component-sm: var(--spacing-100); // 8px
      --space-component-md: var(--spacing-150); // 12px
      --space-component-lg: var(--spacing-200); // 16px
      --space-component-xl: var(--spacing-250); // 20px
      --space-component-xxl: var(--spacing-350); // 28px
    }

    /* Large Desktop+ */
    ${screen.lg} {
      --space-component-xxs: var(--spacing-25); // 2px
      --space-component-xs: var(--spacing-50); // 4px
      --space-component-sm: var(--spacing-100); // 8px
      --space-component-md: var(--spacing-150); // 12px
      --space-component-lg: var(--spacing-200); // 16px
      --space-component-xl: var(--spacing-300); // 24px
      --space-component-xxl: var(--spacing-400); // 36px
    }

    /* ---- Block Spacing ---- */

    /* Mobile+ */
    --space-block-sm: var(--spacing-150); // 12px
    --space-block-md: var(--spacing-250); // 20px
    --space-block-lg: var(--spacing-600); // 48px
    --space-block-xl: var(--spacing-1000); // 80px

    /* Tablet & Small Desktop+ */
    ${screen.sm} {
      --space-block-sm: var(--spacing-150); // 12px
      --space-block-md: var(--spacing-250); // 20px
      --space-block-lg: var(--spacing-600); // 48px
      --space-block-xl: var(--spacing-1000); // 80px
    }

    /* Large Desktop+ */
    ${screen.lg} {
      --space-block-sm: var(--spacing-150); // 12px
      --space-block-md: var(--spacing-300); // 24px
      --space-block-lg: var(--spacing-600); // 48px
      --space-block-xl: var(--spacing-1000); // 80px
    }

    /* ---- Page Spacing ---- */

    /* Mobile+ */
    --space-page-xs: var(--spacing-300); // 24px
    --space-page-sm: var(--spacing-400); // 36px
    --space-page-md: var(--spacing-800); // 64px
    --space-page-lg: var(--spacing-1200); // 96px
    --space-page-xl: var(--spacing-1600); // 128px

    /* Tablet & Small Desktop+ */
    ${screen.sm} {
      --space-page-xs: var(--spacing-300); // 24px
      --space-page-sm: var(--spacing-600); // 48px
      --space-page-md: var(--spacing-900); // 72px
      --space-page-lg: var(--spacing-1400); // 112px
      --space-page-xl: var(--spacing-1800); // 144px
    }

    /* Large Desktop+ */
    ${screen.lg} {
      --space-page-xs: var(--spacing-300); // 24px
      --space-page-sm: var(--spacing-600); // 48px
      --space-page-md: var(--spacing-1000); // 80px
      --space-page-lg: var(--spacing-1500); // 120px
      --space-page-xl: var(--spacing-2000); // 160px
    }

    /* ---- Border Radius Scale ---- */

    --radius-25: 2px;
    --radius-50: 4px;
    --radius-100: 8px;
    --radius-150: 12px;
    --radius-200: 16px;
    --radius-250: 20px;
    --radius-250: 20px;
    --radius-300: 24px;
    --radius-400: 32px;
    --radius-450: 36px;
    --radius-500: 40px;
    --radius-600: 48px;
    --radius-2000: 160px;
    --radius-full: 100vmax;

    /* ---- Border Radius Variables ---- */

    /* Mobile+ */
    --radius-xxs: var(--radius-25);
    --radius-xs: var(--radius-50);
    --radius-s: var(--radius-100);
    --radius-m: var(--radius-150);
    --radius-l: var(--radius-200);
    --radius-xl: var(--radius-300);
    --radius-xxl: var(--radius-450);
    --radius-full: var(--radius-2000);

    /* Tablet+ */
    ${screen.sm} {
      --radius-xxs: var(--radius-25);
      --radius-xs: var(--radius-50);
      --radius-s: var(--radius-100);
      --radius-m: var(--radius-200);
      --radius-l: var(--radius-300);
      --radius-xl: var(--radius-400);
      --radius-xxl: var(--radius-500);
      --radius-full: var(--radius-2000);
    }

    /* Desktop+ */
    ${screen.md} {
      --radius-xxs: var(--radius-50);
      --radius-xs: var(--radius-100);
      --radius-s: var(--radius-150);
      --radius-m: var(--radius-300);
      --radius-l: var(--radius-450);
      --radius-xl: var(--radius-500);
      --radius-xxl: var(--radius-600);
      --radius-full: var(--radius-2000);
    }
    /* ---- New Radius Variables as of July 2024 ---- */

    /* ---- Component Radius ---- */
    /* Mobile+ */
    --radius-component-xs: var(--radius-25); // 2px
    --radius-component-sm: var(--radius-50); // 4px
    --radius-component-md: var(--radius-100); // 8px
    --radius-component-lg: var(--radius-150); // 12px
    --radius-component-xl: var(--radius-200); // 16px
    --radius-component-xxl: var(--radius-400); // 32px
    --radius-component-full: var(--radius-2000); // 160px

    /* Tablet & Small Desktop+ */
    ${screen.sm} {
      --radius-component-xs: var(--radius-25); // 2px
      --radius-component-sm: var(--radius-50); // 4px
      --radius-component-md: var(--radius-100); // 8px
      --radius-component-lg: var(--radius-150); // 12px
      --radius-component-xl: var(--radius-200); // 16px
      --radius-component-xxl: var(--radius-400); // 32px
      --radius-component-full: var(--radius-2000); // 160px
    }

    /* Large Desktop+ */
    ${screen.lg} {
      --radius-component-xs: var(--radius-50); // 4px
      --radius-component-sm: var(--radius-100); // 8px
      --radius-component-md: var(--radius-150); // 12px
      --radius-component-lg: var(--radius-200); // 16px
      --radius-component-xl: var(--radius-250); // 20px
      --radius-component-xxl: var(--radius-500); // 40px
      --radius-component-full: var(--radius-2000); // 160px
    }
  }
`;
