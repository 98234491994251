import { css } from '@emotion/react';

import { colourCss } from '@/styles/colours';
import { legacy } from '@/styles/legacy';
import { measures } from '@/styles/measures';
import { misc } from '@/styles/misc';
import { cssReset } from '@/styles/reset';
import { typography } from '@/styles/typography';

export const baseStyles = css`
  // CSS reset
  ${cssReset}

  // Variables and tokens
  ${colourCss}
  ${typography}
  ${measures}
  ${misc}

  // Legacy stuff
  ${legacy}
`;

export { colourCss, typography, measures, misc };
